import React, { useState } from 'react'
// import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image'
import style from 'styled-components'
import Carousel, { Modal, ModalGateway } from 'react-images'

const CustomView = ({ data }) => (
  <div className="react-images__view">
    <Img
      imgStyle={{
        objectFit: 'scale-down'
      }}
      alt={data.caption}
      fluid={data.childImageSharp.fluid}
    />
  </div>
)

const SmallImage = ({ image, onClick }) => (
  <ImageContainer onClick={onClick}>
    <Img fluid={image.childImageSharp.small} />
  </ImageContainer>
)

const ImageContainer = style.div`
    background: white;
    padding: .5em;
    border-radius: 5px;
    box-shadow: 0 2px 4px lightgray;
`

const Container = style.div`
    width: 40em;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1em;
    grid-template-rows: auto;
    justify-content: center;
    margin: auto;
`

const GridGallery = ({ images, className }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [imageIndex, setImageIndex] = useState(0)
  return (
    <>
      <Container className={className}>
        {images.map((image, index) => (
          <SmallImage
            image={image}
            onClick={() => {
              setModalIsOpen(true)
              setImageIndex(index)
            }}
          />
        ))}
      </Container>
      <ModalGateway>
        {modalIsOpen && (
          <Modal onClose={() => setModalIsOpen(false)}>
            <Carousel
              components={{ View: CustomView }}
              views={images}
              currentIndex={imageIndex}
            />
          </Modal>
        )}
      </ModalGateway>
    </>
  )
}

export default GridGallery
