import React from 'react'
import {
  Link,
  graphql
} from 'gatsby'
import style from 'styled-components'
import Layout from '../components/layout'
import {
  FaRegCalendarAlt,
  FaRegClock
} from 'react-icons/fa'
import tw from 'tailwind.macro'
import GridGallery from '../components/grid-gallery'

const Container = style.div `
  ${tw`m-4 max-w-screen-sm`}
`

const Title = style.div `
  ${tw`mt-8 font-serif text-4xl`}
`

const Subtitle = style.div `
  ${tw`items-center content-center my-2 font-sans text-sm font-light align-middle text-grey-darker`}
`

const Separator = style.a `
  ${tw`mx-2`}
`

const Content = style.div `
  ${tw`font-sans text-xl text-grey-darkest text-justify`}
`

const Footer = style.div `
  ${tw`align-center`}
`

const BlogPost = ({
  data,
  pageContext
}) => {
  const albumImages = data.album.edges.map(edge => {
    return {
      childImageSharp: edge.node.childImageSharp
    }
  })
  const post = data.markdownRemark
  const {
    previous,
    next
  } = pageContext
  return ( <
    Layout colorful = {
      true
    } >
    <
    Container >
    <
    Title > {
      post.frontmatter.title
    } < /Title> <
    Subtitle >
    <
    FaRegCalendarAlt / > {
      post.frontmatter.date
    } <
    Separator > • < /Separator> <
    FaRegClock / > {
      post.timeToRead
    }
    min <
    /Subtitle> <
    Content dangerouslySetInnerHTML = {
      {
        __html: post.html
      }
    }
    /> <
    Footer > {
      previous ? < Link to = {
        previous.fields.slug
      }
      /> : <></ >
    } {
      next ? < Link to = {
        next.fields.slug
      }
      /> : <></ >
    } <
    /Footer> <
    GridGallery images = {
      albumImages
    }
    /> < /
    Container > <
    /Layout>
  )
}

export const query = graphql `
  query BlogPostQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      frontmatter {
        title
        date(formatString: "DD/MM/YYYY")
      }
      fields {
        slug
      }
    }
    album: allFile(
      filter: { absolutePath: { regex: $slug }, name: { glob: "*.album*" } },
      sort: {fields: name, order: ASC}
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 4000) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
            small: fluid(maxWidth: 300, maxHeight: 200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default BlogPost